<script setup lang="ts">
import type { CornerRadius } from '@gem/control/src/control/radius/types';
import { ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'changeByKey', key: string, value: any): void;
  (e: 'controlChange', value: CornerRadius): void;
  (e: 'controlOnChange', value: CornerRadius): void;
  (e: 'updateLinked', value: boolean): void;
}>();

type Props = {
  value?: CornerRadius;
  disable?: boolean;
  circleValue?: number | string;
  isLinked?: boolean;
};

type OptionItem = {
  title: string;
  value: string;
  keyword: 'circle' | 'small' | 'medium' | 'large' | 'extraLarge';
  valueDisplay?: string;
  showValue?: boolean;
};

const props = defineProps<Props>();

const options: OptionItem[] = [
  {
    title: 'Circle',
    value: `${props.circleValue}px`,
    keyword: 'circle',
  },
  {
    title: 'Small',
    value: '4px',
    keyword: 'small',
    showValue: true,
  },
  {
    title: 'Medium',
    value: '8px',
    keyword: 'medium',
    showValue: true,
  },
  {
    title: 'Large',
    value: '16px',
    keyword: 'large',
    showValue: true,
  },
  {
    title: 'Extra large',
    value: '32px',
    keyword: 'extraLarge',
    showValue: true,
  },
];

const val = ref(props.value);

const borderRadiusInputs = [
  {
    key: 'btlr',
    iconName: 'border-radius-bottom-left',
    iconClass: 'rotate-90',
    iconPosition: 'first',
  },
  {
    key: 'btrr',
    iconName: 'border-radius-bottom-left',
    iconClass: 'rotate-180',
    iconPosition: 'last',
  },
  {
    key: 'bblr',
    iconName: 'border-radius-bottom-left',
    iconPosition: 'first',
  },
  {
    key: 'bbrr',
    iconName: 'border-radius-bottom-left',
    iconClass: '-rotate-90',
    iconPosition: 'last',
  },
];

const onChangeInput = (key: string, value?: string) => {
  handleEmit('onChange', key, value);
};

const changeInput = (key: string, value?: string) => {
  handleEmit('change', key, value ?? '0');
};

const blurInput = (key: string, value?: string, popoverOpen?: boolean) => {
  if (value === '0' && !popoverOpen) {
    handleEmit('change', key, value);
  }
  if (value === '') {
    handleEmit('change', key, '0');
  }
};

const handleLink = () => {
  emit('updateLinked', !props.isLinked);
};

const getValueByKey = (key: string) => {
  const valueByKey = val.value?.[key as keyof CornerRadius] as string;
  if (valueByKey) {
    return valueByKey.includes('%') ? valueByKey : convertValueToNumber(valueByKey);
  }
  return valueByKey;
};

const getStringValueByKey = (key: string) => {
  return (val.value?.[key as keyof CornerRadius] as string) || '0px';
};

const handleEmit = (type: 'change' | 'onChange', key: string, value?: string) => {
  let valueChange = value;
  if (value && !value?.includes('%')) {
    valueChange = convertValueToNumber(value) + 'px';
  }
  const dataChange = props.isLinked
    ? {
        ...val.value,
        bblr: valueChange,
        bbrr: valueChange,
        btlr: valueChange,
        btrr: valueChange,
      }
    : {
        ...val.value,
        [key]: valueChange,
      };
  if (type === 'onChange') {
    val.value = dataChange;
    emit('controlOnChange', dataChange);
  } else emit('controlChange', dataChange);
};

const convertValueToNumber = (value: string) => {
  const num = parseInt(value);
  return isNaN(num) ? 0 : num;
};

watch(
  () => props.value,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      val.value = newVal;
    }
  },
);
</script>

<template>
  <div class="flex flex-col">
    <div class="bg-dark-400 relative grid grid-cols-2 gap-x-[8px] gap-y-24 rounded-xl p-4">
      <g-popover
        v-for="(item, index) in borderRadiusInputs"
        :key="index"
        no-shadow-box
        :has-arrow="false"
        :closeable="true"
        :overlay="false"
        overlay-container="#root-modal"
        wrapper-class="translate-x-[-8px]"
        :placement-offset="0"
        placement="bottom-start">
        <template #default="{ open }">
          <g-input
            :value="getValueByKey(item.key)"
            type="text"
            input-style="secondary"
            size="small"
            :icon-position="item.iconPosition"
            classes="gemx-hidden-arrow text-center"
            @on-change="(value) => onChangeInput(item.key, value)"
            @change="(value) => changeInput(item.key, value)"
            @handle-blur="(value) => blurInput(item.key, value, open)">
            <template #icon>
              <g-base-icon
                class="text-dark-150"
                :class="item.iconClass"
                :name="(item.iconName as any)"
                width="16"
                height="16"
                viewBox="0 0 20 20" />
            </template>
          </g-input>
        </template>
        <template v-if="options?.length" #content="{ close }">
          <div
            class="radius-option-select rounded-12 bg-dark-400 shadow-dark-device w-[180px] overflow-hidden p-8 transition-all">
            <div
              v-for="(opt, i) in options"
              :key="i"
              class="text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
              @click.prevent="
                () => {
                  changeInput(item.key, opt.value);
                  close();
                }
              ">
              <span
                v-if="getStringValueByKey(item.key).toLocaleLowerCase() === opt.value.toLocaleLowerCase()"
                class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                <GBaseIcon name="status-check" width="20" height="20" />
              </span>
              <p class="flex w-full items-center justify-between truncate">
                <span class="truncate">{{ opt.title }}</span>
                <span v-if="opt.showValue" class="text-text-dark-300">{{ opt.valueDisplay || opt.value }}</span>
              </p>
            </div>
          </div>
        </template>
      </g-popover>
      <div class="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
        <GButtonV2
          icon-view-box="0 0 16 16"
          class="bg-dark-500"
          type="ghost"
          :only-icon="isLinked ? 'spacing-link' : 'spacing-un-link'"
          size="small"
          @click="handleLink">
        </GButtonV2>
      </div>
    </div>
  </div>
</template>
